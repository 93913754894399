@import '/src/stylesheets/variables.scss';

.default-icon-color {
  color: $default-icon-color;
}

.standard-icon::before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  color: $default-icon-color;
}

.add-icon::before {
  @extend .standard-icon;
  // plus icon
  content: '\f067';
  color: $success;
}

.edit-icon::before {
  @extend .standard-icon;
  // pencil alt icon
  content: '\f303';
}

.delete-icon::before {
  @extend .standard-icon;
  // trash alt icon
  content: '\f2ed';
  color: $danger;
}

.save-icon::before {
  @extend .standard-icon;
  // save icon
  content: '\f0c7';
  color: $primary;
}

.cancel-icon::before {
  @extend .standard-icon;
  // times circle icon
  content: '\f057';
  font-weight: 400;
}

.required-icon-size {
  font-size: 6px;
  position: relative;
  top: -5px;
}

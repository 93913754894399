@import '/src/stylesheets/variables.scss';

$sb-nav-sidebar-font-color: #ffffffe9;
$sb-nav-sidebar-font-hover-color: #ffffff9c;
$nav-link-active-bg: #0d6efd;

.nav-bar-general-style {
  background-color: $gray-900 !important;
  box-shadow: 2px 2px 10px $black;
}

.sb-nav-link-icon,
.sb-sidenav-menu .nav-link .sb-nav-link-icon {
  margin-right: 0.5rem;
}

.sb-nav-fixed #layoutSidenav #layoutSidenav_nav,
.sb-nav-fixed .sb-topnav {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.sb-nav-fixed .sb-topnav {
  z-index: 1039;
}

#layoutSidenav {
  display: flex;
}

#layoutSidenav #layoutSidenav_nav {
  flex-basis: 225px;
  flex-shrink: 0;
  transition: transform 0.15s ease-in-out;
  z-index: 1038;
  transform: translateX(-225px);
}

.sb-nav-fixed #layoutSidenav #layoutSidenav_nav {
  width: 225px;
  height: 100vh;
  z-index: 1038;
}

.sb-nav-fixed #layoutSidenav #layoutSidenav_nav .sb-sidenav {
  padding-top: 53.6px;
}

.sb-nav-fixed #layoutSidenav #layoutSidenav_nav .sb-sidenav .sb-sidenav-menu {
  overflow-y: auto;
}

.sb-nav-fixed #layoutSidenav #layoutSidenav_content {
  padding-left: 225px;
  top: 53.6px;
}

#layoutSidenav #layoutSidenav_content main {
  height: calc(100vh - 96.4px);
}

#layoutSidenav #layoutSidenav_content .sidenav-content-footer {
  height: 44px;
  line-height: 3.1em;
  box-shadow: 5px 10px 15px $black;
  z-index: 10;
}

#layoutSidenav #layoutSidenav_content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 0;
  flex-grow: 1;
  min-height: calc(100vh - 56px);
  margin-left: -225px;
}

.sb-sidenav-toggled #layoutSidenav #layoutSidenav_content:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $black;
  z-index: 1037;
  opacity: 0.5;
  transition: opacity 0.3s ease-in-out;
}

.sb-sidenav-toggled #layoutSidenav #layoutSidenav_nav {
  transform: translateX(0);
}

@media (min-width: 992px) {
  #layoutSidenav #layoutSidenav_nav {
    transform: translateX(0);
  }

  #layoutSidenav #layoutSidenav_content {
    margin-left: 0;
    transition: margin 0.15s ease-in-out;
  }

  .sb-sidenav-toggled #layoutSidenav #layoutSidenav_nav {
    transform: translateX(-225px);
  }

  .sb-sidenav-toggled #layoutSidenav #layoutSidenav_content {
    margin-left: -225px;
  }

  .sb-sidenav-toggled #layoutSidenav #layoutSidenav_content:before {
    display: none;
  }
}

.sb-sidenav {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-wrap: nowrap;
}

.sb-sidenav .sb-sidenav-menu {
  flex-grow: 1;
}

.sb-sidenav .sb-sidenav-menu .nav {
  flex-direction: column;
  flex-wrap: nowrap;
}

.sb-sidenav .sb-sidenav-menu .nav .sb-sidenav-menu-heading {
  padding: 1.25rem 1rem 0.75rem;
  font-size: 0.75rem;
  font-weight: bold;
  text-transform: uppercase;
}

.sb-sidenav .sb-sidenav-menu .nav .nav-link {
  display: flex;
  align-items: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  position: relative;
}

.sb-sidenav .sb-sidenav-menu .nav .nav-link .sb-nav-link-icon {
  font-size: 0.9rem;
}

.sb-sidenav .sb-sidenav-menu .nav .nav-link .sb-sidenav-collapse-arrow {
  display: inline-block;
  margin-left: auto;
  transition: transform 0.15s ease;
}

.sb-sidenav .sb-sidenav-menu .nav .nav-link.collapsed .sb-sidenav-collapse-arrow {
  transform: rotate(-90deg);
}

.sb-sidenav .sb-sidenav-menu .nav .sb-sidenav-menu-nested {
  flex-direction: column;
  text-indent: 10px;
}

.sb-sidenav .sb-sidenav-footer {
  padding: 0.75rem;
  flex-shrink: 0;
}

.sb-sidenav-dark {
  background-color: $gray-900 !important;
  color: $sb-nav-sidebar-font-color;
}

.sb-sidenav-dark .sb-sidenav-menu .sb-sidenav-menu-heading {
  color: $sb-nav-sidebar-font-hover-color;
}

.sb-sidenav-dark .sb-sidenav-menu .nav-link,
.sb-nav-fixed .navbar .nav-link {
  cursor: pointer;
  color: $sb-nav-sidebar-font-color;
}

.sb-sidenav-dark .sb-sidenav-menu .nav-link .sb-nav-link-icon {
  color: $sb-nav-sidebar-font-hover-color;
}

.sb-sidenav-dark .sb-sidenav-menu .nav-link .sb-sidenav-collapse-arrow {
  color: $sb-nav-sidebar-font-hover-color;
}

.sb-sidenav-dark .sb-sidenav-menu .nav-link:hover,
.sb-nav-fixed .navbar .nav-link:hover {
  color: $white !important;
}

.sb-sidenav-dark .sb-sidenav-menu .nav-link.active {
  color: $white;
  background: linear-gradient(to left, $gray-800 5%, $gray-800 98%, $nav-link-active-bg 2%);
}

.sb-sidenav-dark .sb-sidenav-menu .nav-link.active .sb-nav-link-icon {
  color: $white;
}

.sb-sidenav-dark .sb-sidenav-footer {
  background-color: $gray-800;
}

.sb-sidenav-light {
  background-color: $gray-100;
  color: $gray-900;
}

.sb-sidenav-light .sb-sidenav-menu .sb-sidenav-menu-heading {
  color: $gray-500;
}

.sb-sidenav-light .sb-sidenav-menu .nav-link {
  color: $gray-900;
}

.sb-sidenav-light .sb-sidenav-menu .nav-link .sb-nav-link-icon {
  color: $gray-500;
}

.sb-sidenav-light .sb-sidenav-menu .nav-link .sb-sidenav-collapse-arrow {
  color: $gray-500;
}

.sb-sidenav-light .sb-sidenav-menu .nav-link:hover {
  color: $nav-link-active-bg;
}

.sb-sidenav-light .sb-sidenav-menu .nav-link.active {
  color: $nav-link-active-bg;
}

.sb-sidenav-light .sb-sidenav-menu .nav-link.active .sb-nav-link-icon {
  color: $nav-link-active-bg;
}

.sb-sidenav-light .sb-sidenav-footer {
  background-color: $gray-200;
}

.sb-sidenav-menu::-webkit-scrollbar {
  width: 5px !important;
}

.sb-sidenav-menu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px $gray-900;
  background: inherit !important;
}

.sb-sidenav-menu::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 5px $gray-900;
  background-color: $gray-600;
  border-color: $gray-900;
}
.ng-wizard-customized {
  .ng-wizard-main {
    border-radius: 0 !important;
    box-shadow: none !important;
    margin-top: 10px;
    min-height: calc(100% - 12px);
    display: flex;
    flex-direction: column;

    .ng-wizard-container {
      flex: 1;
      min-height: initial !important;

      .tab-pane {
        padding: 16px !important;
      }
    }
  }

  .ng-wizard-toolbar {
    display: none;
  }
}

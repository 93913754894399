form div.bd-highlight {
  max-height: 100px;
  width: 480px;
}

.input-group-prepend {
  width: 250px;
}

input.form-control {
  background-color: white;
  border: 1px solid lightgray;
}

input.form-control-file {
  max-height: 37px;
  line-height: 1.2em;
}

div.input-group > div.text-danger {
  width: 480px;
}

.fileInput {
  width: 250px;
  height: 38px;
  padding-left: 40px;
}

.custom-checkbox-style {
  width: 16px;
  height: 16px;
}

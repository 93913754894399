.ng-select {
    padding-bottom: 0;
}
.ng-select.ng-select-disabled .ng-select-container:after {
    border-bottom-color: transparent;
    background-image: linear-gradient(to right, #3131316b 0%, #3131316b 33%, transparent 0%);
    background-size: 2px 1px;
    background-repeat: repeat-x;
}
.ng-select.ng-select-disabled .ng-select-container .ng-value-container .ng-value {
    color: #00000061;
}
.ng-select.ng-select-disabled .ng-select-container .ng-value-container .ng-placeholder {
    color: #00000061;
}
.ng-select.ng-select-disabled .ng-arrow-wrapper .ng-arrow,
.ng-select.ng-select-disabled .ng-clear-wrapper {
    color: #00000061;
}
.ng-select.ng-select-focused .ng-select-container:after {
    border-color: #3f51b5;
    border-width: 2px;
}
.ng-select.ng-select-focused .ng-select-container.ng-appearance-outline:after,
.ng-select.ng-select-focused .ng-select-container.ng-appearance-outline:hover:after {
    border-color: #3f51b5;
    border-width: 2px;
}
.ng-select.ng-select-focused .ng-select-container .ng-value-container .ng-placeholder {
    transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
    color: #3f51b5;
}
.ng-select.ng-select-focused .ng-select-container .ng-arrow-wrapper .ng-arrow {
    color: #3f51b5;
}
.ng-select .ng-has-value .ng-placeholder,
.ng-select.ng-select-filtered .ng-select-container .ng-placeholder {
    display: initial;
}
.ng-select .ng-has-value .ng-placeholder,
.ng-select.ng-select-opened .ng-placeholder {
    transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
}
.ng-select .ng-select-container {
    color: #000000de;
    align-items: baseline;
    min-height: 51.5px;
    background-color: $standard-components-background-color;
    margin-top: 10px;
}
.ng-select .ng-select-container:after {
    border-bottom: 2px solid #b6b5b5;
    content: '';
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    transition: border-color 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
}
.ng-select .ng-select-container.ng-appearance-outline {
    padding: 0 0.5em;
    min-height: 60px;
}
.ng-select .ng-select-container.ng-appearance-outline:after {
    border: solid 2px #b6b5b5;
    border-radius: 5px;
    height: calc(100% - 0.5em);
    pointer-events: none;
    transition: border-color 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.ng-select .ng-select-container.ng-appearance-outline:hover:after {
    border-color: #000000de;
    border-width: 2px;
}
.ng-select .ng-select-container.ng-appearance-outline .ng-placeholder {
    padding: 0 0.25em;
    background-color: #fff;
    z-index: 1;
}
.ng-select .ng-select-container.ng-appearance-outline .ng-value {
    padding-left: 0.25em;
}
.ng-select .ng-select-container .ng-value-container {
    align-items: stretch;
    padding: 0.4375em 0;
    border-top: 0.84375em solid transparent;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
    position: absolute;
    color: #0000008a;
    transform-origin: left 0;
    transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), width 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
[dir='rtl'] .ng-select .ng-select-container .ng-value-container .ng-placeholder {
    transform-origin: right 0;
}
.ng-select .ng-select-container .ng-value-container .ng-input {
    bottom: 1.4375em;
}
.ng-select.ng-select-single .ng-select-container.ng-appearance-outline .ng-arrow-wrapper {
    bottom: 17px;
}
.ng-select.ng-select-single .ng-select-container.ng-appearance-outline .ng-clear-wrapper {
    bottom: 14px;
}
.ng-select.ng-select-single .ng-select-container .ng-arrow-wrapper {
    align-self: flex-end;
    bottom: 9px;
}
.ng-select.ng-select-single .ng-select-container .ng-clear-wrapper {
    align-self: flex-end;
    bottom: 7px;
}
.ng-select.ng-select-multiple.ng-select-disabled .ng-select-container .ng-value-container .ng-value {
    background-color: #0000001f;
    color: #00000042;
}
.ng-select.ng-select-multiple.ng-select-disabled .ng-appearance-outline:after,
.ng-select.ng-select-multiple.ng-select-disabled .ng-appearance-outline:hover:after {
    background-image: none;
    border: dotted 1px #0000001f;
}
.ng-select.ng-select-multiple .ng-select-container.ng-appearance-outline.ng-has-value .ng-arrow-wrapper,
.ng-select.ng-select-multiple .ng-select-container.ng-appearance-outline.ng-has-value .ng-clear-wrapper {
    border-top: none;
}
.ng-select.ng-select-multiple .ng-select-container.ng-appearance-outline .ng-arrow-wrapper {
    top: 0;
}
.ng-select.ng-select-multiple .ng-select-container.ng-appearance-outline .ng-clear-wrapper {
    top: 2px;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    background-color: #3f51b5;
    border-radius: 2px;
    color: #fff;
    padding: 2px 5px;
    margin: 0 0.4375em 0.4375em 0;
}
[dir='rtl'] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    margin: 0 0 0.4375em 0.4375em;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled {
    background-color: rgba(0, 0, 0, 0.12);
    color: rgba(0, 0, 0, 0.26);
}
// .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
//     font-size: 14px;
//     font-weight: 500;
// }
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon {
    color: rgba(255, 255, 255, 0.54);
    padding-right: 5px;
}
[dir='rtl'] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon {
    padding-left: 5px;
    padding-right: 0;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover {
    color: rgba(255, 255, 255, 0.87);
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
    line-height: 1.375em;
}
.ng-select.ng-select-multiple .ng-select-container.ng-has-value {
    align-items: center;
}
.ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-value-container {
    padding-bottom: 0;
    padding-top: 0.1875em;
}
.ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-clear-wrapper,
.ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-arrow-wrapper {
    border-top: 0.84375em solid transparent;
}
.ng-select .ng-clear-wrapper {
    color: #0000008a;
}
.ng-select .ng-clear-wrapper:hover {
    color: #000000de;
}
.ng-select .ng-arrow-wrapper {
    bottom: 2px;
}
.ng-select .ng-arrow-wrapper .ng-arrow {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid;
    margin: 0 4px;
    color: #0000008a;
}
.ng-select .ng-spinner-zone {
    top: 3px;
}
.ng-dropdown-panel {
    background: #fff;
    left: 0;
}
.ng-dropdown-panel.ng-select-bottom {
    top: calc(100% - 1.25em);
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.ng-dropdown-panel.ng-select-top {
    bottom: calc(100% - 0.84375em);
    box-shadow: 0 -5px 5px -3px rgba(0, 0, 0, 0.2), 0 -8px 10px 1px rgba(0, 0, 0, 0.14), 0 -3px 14px 2px rgba(0, 0, 0, 0.12);
}
.ng-dropdown-panel.multiple .ng-option.selected {
    background: #fff;
}
.ng-dropdown-panel.multiple .ng-option.marked {
    background: rgba(0, 0, 0, 0.04);
}
.ng-dropdown-panel .ng-dropdown-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding: 0 16px;
    line-height: 3em;
    min-height: 3em;
}
.ng-dropdown-panel .ng-dropdown-footer {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    padding: 0 16px;
    line-height: 3em;
    min-height: 3em;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
    user-select: none;
    cursor: pointer;
    line-height: 3em;
    height: 3em;
    padding: 0 16px;
    color: rgba(0, 0, 0, 0.54);
    // font-weight: 500;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-marked {
    background: rgba(0, 0, 0, 0.04);
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-disabled {
    cursor: default;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected {
    background: rgba(0, 0, 0, 0.12);
    color: #3f51b5;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    line-height: 3em;
    min-height: 3em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 16px;
    text-decoration: none;
    position: relative;
    color: rgba(0, 0, 0, 0.87);
    text-align: left;
}
[dir='rtl'] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    text-align: right;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    background: rgba(0, 0, 0, 0.04);
    color: rgba(0, 0, 0, 0.87);
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
    background: rgba(0, 0, 0, 0.12);
    color: #3f51b5;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
    color: rgba(0, 0, 0, 0.38);
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
    padding-left: 32px;
}
[dir='rtl'] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
    padding-right: 32px;
    padding-left: 0;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label {
    padding-right: 5px;
    // font-size: 80%;
    // font-weight: 400;
    color: rgba(0, 0, 0, 0.38);
}
[dir='rtl'] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label {
    padding-left: 5px;
    padding-right: 0;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container,
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
    padding-top: 6px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
}

// Import variables, icons, ...
@import 'variables.scss';
@import './general-icons/general-icons.scss';

// Import npm package styles (e.g. bootstrap, fortawesome)
@import '@fortawesome/fontawesome-free/css/all.min.css';
@import 'bootstrap/scss/bootstrap';
@import 'ngx-toastr/toastr';

/* Mandatory */
@import 'node_modules/ng-wizard3/themes/ng_wizard.min.css';

// Import third-party styles
@import '/src/stylesheets/global-styles/inputs.scss';
@import '/src/stylesheets/global-styles/drag-drop.scss';
@import '/src/stylesheets/global-styles/table.scss';
@import '/src/stylesheets/third-party/sb-sidenav/sb-sidenav.scss';
@import './third-party/ngx-datatable/ngx-datatable-material.scss';
@import './third-party/ng-select/ng-select.material.theme.scss';
@import './third-party/ng-wizard/ng-wizard.scss';
@import './third-party/modal-dialogs/modal-dialogs.scss';

// Import styles
@import './buttons/buttons.scss';
@import '/src/stylesheets/global-styles/global-styles.scss';

a.link-primary {
  cursor: pointer;
}

div {
  cursor: default;
}

.panel-heading {
  padding-top: 15px;
}

.online-indication {
  background: $success;
  display: inline-block;
  width: 0.8em;
  height: 0.8em;
  border-radius: 50%;
  margin-bottom: 2px;
}

.offline-indication {
  background: $danger;
  display: inline-block;
  width: 0.8em;
  height: 0.8em;
  border-radius: 50%;
  margin-bottom: 2px;
}

.row > * {
  padding-left: calc(var(--bs-gutter-x) * 0.125);
  padding-right: calc(var(--bs-gutter-x) * 0.125);
}

$btn-border-radius: 0.125rem;
$btn-border-radius-lg: 0.2rem;
$btn-border-radius-sm: 0.1rem;
$btn-font-size: 15px;
$btn-font-size-lg: 17px;
$btn-font-size-sm: 13px;
$btn-focus-box-shadow: 0 5px 11px 0 #0000002e, 0 4px 15px 0 #00000026;
$btn-active-box-shadow: 0 2px 5px 0 #00000029, 0 2px 10px 0 #0000001f;

$btn-padding-y: 8px;
$btn-padding-x: 15px;
$font-color: $white;

.btn {
  display: inline-block;
  font-size: 0.81rem;
  transition: 0.2s ease-in-out;
  border: 0;
  cursor: if($enable-button-pointers, pointer, null);
  white-space: $btn-white-space;
  word-wrap: break-word;
  font-weight: 300;
  font-size: 15px;
  vertical-align: middle;
  text-decoration: if($link-decoration == none, null, none);
  text-align: center;
  user-select: none;

  @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-border-radius);
  @include transition($btn-transition);

  &:hover {
    text-decoration: if($link-hover-decoration == underline, none, null);
  }

  .btn-check:focus + &,
  &:focus {
    outline: 0;
    box-shadow: $btn-focus-box-shadow;
  }

  .btn-check:checked + &,
  .btn-check:active + &,
  &:active,
  &.active {
    @include box-shadow($btn-active-box-shadow);

    &:focus {
      @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
    }
  }

  &:disabled,
  &.disabled,
  fieldset:disabled & {
    pointer-events: none;
    opacity: $btn-disabled-opacity;
    @include box-shadow(none);
  }
}

//
// Alternate buttons
//

// scss-docs-start btn-variant-loops
@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
    margin: 3px;
    box-shadow: $btn-active-box-shadow;
  }
}

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}
// scss-docs-end btn-variant-loops

//
// Link buttons
//

// Make a button look and behave like a link
.btn-link {
  font-weight: $font-weight-normal;
  color: $btn-link-color;
  text-decoration: $link-decoration;

  &:hover {
    color: $btn-link-hover-color;
    text-decoration: $link-hover-decoration;
  }

  &:focus {
    text-decoration: $link-hover-decoration;
  }

  &:disabled,
  &.disabled {
    color: $btn-link-disabled-color;
  }

  // No need for an active state here
}

//
// Button Sizes
//

.btn-lg {
  @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $btn-font-size-lg, $btn-border-radius-lg);
}

.btn-sm {
  @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-border-radius-sm);
}

// Default Bootstrap variables

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$primary: #186db6;
$secondary: #6c757d;
$success: #067223;
$info: #17a2b8;
$warning: #ffa351ff;
$danger: #bf0a30;
$light: #f8f9fa;
$dark: #343a40;

$font-size-base: 0.9rem;
$body-color: #5f5e5e;
$font-weight-bold: 500;

$standard-font-hover-color: #858484;
$standard-border-color: #e2e2e2;
$disabled-color: $gray-500;
$default-icon-color: $gray-600;
$standard-link-color: $dark;
$standard-link-hover-color: #e2e2e2;

$input-border-radius: 0.15rem;
$standard-components-background-color: #2f3d560a;
$standard-components-border-color: #b6b5b5;
$standard-caption-color: #6d6c6c;
$standard-components-primary-border-color: #3f51b5;
$standard-components-secondary-border-color: #a8b5ff;

$toggle-switch-not-active-state-color: #ccc;
$toggle-switch-background-color: #f7f7f8;

$table-group-separator-color: #0000001f;
$table-th-font-weight: 450;
$table-striped-bg: transparent;
$table-accent-bg: #eeeded83;
$table-selected-tr-bg: #e7f3e6;

body {
  min-height: 100vh;
  overflow: hidden;

  a {
    cursor: pointer;
    text-decoration: none;
  }

  hr {
    border: none;
    border-bottom: solid 1px $gray-500;
    margin: 0 0 15px 0;
  }

  .flex-vertical-container {
    display: flex;
    flex-flow: column;
  }

  .disabled {
    pointer-events: none;
    cursor: default;
    color: $disabled-color !important;
    opacity: $btn-disabled-opacity !important;

    * {
      color: $disabled-color !important;
      opacity: 100% !important;
    }
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .drag-handle {
    cursor: move;
  }

  .form-switch-full-opacity {
    opacity: 1 !important;
  }

  .customized-table {
    margin-bottom: 0;

    th {
      font-size: 15px;
      font-weight: 400;
      background-color: transparent !important;
      box-shadow: none !important;
      border-bottom-width: 1px;
    }

    td {
      padding-top: 10px !important;
      padding-bottom: 7px !important;
      width: auto;
      vertical-align: top;
    }
  }
}

.required .ng-select-container .ng-placeholder:after {
  content: ' *' !important;
  font-weight: 600 !important;
  color: $danger !important;
}

.modal {
  z-index: 99999;
}

.modal-content {
  border: none;
  border-radius: 0.125rem;
  box-shadow: 0 5px 11px 0 $gray-600, 0 4px 15px 0 $gray-600;
  margin-top: 75px !important;

  .modal-header {
    background-color: $dark;
    border-radius: 0.125rem;
    height: 50px;

    .modal-title {
      font-weight: 300;
      font-size: 18px;
      color: $white;
      line-height: 0.8em;
    }

    .btn-light {
      background-color: transparent !important;
      box-shadow: none !important;
      color: $white;
      font-size: 22px;
      font-weight: 400;
    }

    .close {
      font-weight: 800;
      line-height: 0.4em;
      color: $white;
      text-shadow: none;
      outline-width: 0 !important;
      outline: none !important;
    }
  }

  .modal-body {
    padding: 10px;
    font-size: 15px;
    font-weight: 300;
  }

  .modal-footer {
    padding: 7px;
    border-top: solid 1px $standard-border-color;
  }
}
